.modalButton {
  margin-left: 10px;
  background-color: #BCD8C5;
  padding: 3px 0px;
  width: 6rem;
  font-size: 13px;
  cursor: pointer;
}

.modalText {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.6rem;
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 10px 60px;
}

.modaldecription {
  font-size: 13px;
  color: rgba(0, 0, 0);
  align-items: center;
  text-wrap: pretty;
  overflow-y: auto;
  max-height: 300px;
}

.modalDesc {
  align-items: center;
  align-content: center;
}

.modalButton1 {
  background-color: #3B755E;
  padding: 3px 0px;
  font-weight: 400;
  font-size: 13px;
  color: white;
  width: 6rem;
  cursor: pointer;
}

.modalButton1.disabled {
  background-color: #808080;
  cursor: not-allowed;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 5px;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}